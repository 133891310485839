h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $redhat;
  color: $blue-1;
  letter-spacing: 0;
  margin-bottom: 22px;
  letter-spacing: 0.5px;
  line-height: 1.15em;

  &:last-child {
    margin-bottom:0;
  }
}

p {
  font-family: $libre;
  color: $blue-1;
  font-size:18px;
  line-height:1.55em;
  font-weight:400;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
  &:empty {
    display: none;
  }
}

a {
  font-weight:600;
}

#main-content > section h1 {
  font-size:80px;
}

h1, .h1 {
  font-size:64px;
}

h2, .h2 {
  font-size:48px;
}

h3, .h3 {
  font-size:32px;
}

.txt-size-36, .txt-size-36 p {
  font-size:36px;
}

.txt-size-24, .txt-size-24 p {
  font-size:24px;
}

.txt-size-14,
.txt-size-14 p {
  font-size:14px;
}

.txt-size-12,
.txt-size-12 p,
.overline p,
.overline {
  font-size:12px;
}

.txt-size-10,
.txt-size-10 p {
  font-size: 10px;
}

.title-highlight {
  font-size:24px;
  font-weight:600;
}

.list-title,
.list-title h3 {
  font-family: $libre;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.text-headline {
  text-transform: uppercase;
  font-family: $libre;
  font-size: 14px;
  font-weight: 250;
  letter-spacing: 7px;
  text-align: left;
}

.text-overline {
  font-family: $libre;
  font-weight: 200;
  font-size: 14px;
  text-transform: uppercase;
}

/* Main font sizes and line heights */
@media screen and (max-width:500px) {
  #main-content > section h1 {
    font-size:56px;
  }

  h1, .h1 {
    font-size:48px;
  }

  h2, .h2 {
    font-size:32px;
  }

  h3, .h3 {
    font-size:24px;
  }

  .title-highlight {
    font-size:22px;
  }
}

.ff-libre {
  font-family: $libre;
}

.ff-redhat {
  font-family: $redhat;
}

.fw {
  &-300 {
    font-weight: 300;
  }

  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }

  &-800 {
    font-weight: 800;
  }
}

.letter-spacing {
  letter-spacing:0.25vw;
}

// text color classes
.text-white, .text-white * {
  color: $white;
}
.text-blue, .text-blue * {
  color: $blue-1;
}
.c {
  &-black {
    color: $black;
  }

  &-white {
    color: $white;

    a:not(.btn):not(.button) {
      color: $green-1;
      text-decoration: underline;
    }

    h2 a,
    h3 a {
      text-decoration:none;
    }
  }

  &-blue, &-blue-1 {
    color: $blue-1;

    a:not(.btn):not(.button) {
      color: $blue-1;
      text-decoration: underline;
    }

    h2 a,
    h3 a {
      text-decoration:none;
    }
  }

  &-red-2 {
    color: $red-2;
  }

  &-blue-7 {
    color: $blue-7;
  }

  &-blue-15 {
    color: $blue-15;
  }

  &-green-1 {
    color: $green-1;
  }

  &-gray-2 {
    color: $gray-2;
  }

  &-gray-8 {
    color: $gray-8;
  }

  &-gray-18 {
    color: $gray-18;
  }

  &-blue-5 {
    color: $blue-5;
  }

  &-green-5 {
    color: $green-5;
  }
}

ul.checklist,
.green-checkmark {
  list-style-image: url($ROOT_PATH + 'media/svgs/green-check.svg');
  li {
    margin-bottom: 8px;
    padding-left:8px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:before {
      content:none!important;
    }
  }
}

.phone-icon {
  display: inline-flex;
  &:before {
    margin-right: 15px;
    content: '';
    display: inline-block;
    background-image: url($ROOT_PATH + 'media/svgs/phone-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    position: relative;
  }
}
