/*
.article-content,
.sk-lander {

}
*/
.hbspt-form {
  .hs-richtext.hs-main-font-element {
    padding: 0;
    font-weight: 700;
    margin-bottom:20px;
  }
  
  .hs-form-field {
    position: relative;
    margin-bottom: 30px !important;
    label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
      position: absolute;
      z-index: 3;
      top: -12px;
      left: 22px;
      color: $white;
      background-color: $blue-1;
      padding: 0 10px 0 10px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.6px;
      font-family: $libre;

      @media screen and ($max1024) {
        position: relative;
        top: 0;
        left: 0;
        background-color: transparent;
        display: block;
        margin-bottom: 8px;
      }

      @media screen and ($min1024) {
        @include vw(top, -12px);
        @include vw(left, 22px);
        @include vw(padding, 0 10px 0 10px);
        letter-spacing: 0.6px;
      }

      @media screen and ($min1920) {
        top: -12px;
        left: 22px;
        padding: 0 10px 0 10px;
        letter-spacing: 0.6px;
      }
    }
    .hs-error-msgs {
      .hs-main-font-element {
        padding: 0;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.6px;
        line-height: 20px;
      }
    }
    label.hs-form-booleancheckbox-display {
      display: flex;
      position: relative !important;
      top: auto !important;
      left: auto !important;
      padding: 0 !important;
      margin-bottom: 5px;
    }
    .hs-form-booleancheckbox {
      list-style-type: none;
      &::before {
        content: none !important;
      }
    }
  }
}

.home-page-form {
  .hbspt-form {
    .hs-form-field {
      label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
        position:absolute;
        top: 6px;
        left: 0;
        z-index: 0;
        width: 90%;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 30px;
        color: #fff;
        background-color:transparent;
        backface-visibility: hidden;
        transform-origin: 0 50%;
        transition:
          top 0.25s cubic-bezier(0.61, 1, 0.88, 1),
          color 0.25s cubic-bezier(0.61, 1, 0.88, 1),
          transform 0.25s cubic-bezier(0.61, 1, 0.88, 1),
          opacity 0.25s cubic-bezier(0.61, 1, 0.88, 1);
  
        &.hide {
          display: none !important;
        }
      }
    }
  }
}

.home-page-form,
.landing-page-form,
.popup {
  .hbspt-form {
    .hs-error-msgs .hs-main-font-element {
      color: #17fcc4;
    }
  }
}

.contacts-tab-form,
.contact-section {
  .hbspt-form {
    .hs-form-field {
      label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
        color: #002ea6;
        background-color: #fff;
      }
    }
  }
}
