.inner-pages-quiz-section {
  background-color: $blue-11;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and ($min1024) {
    min-height: 110vh;
    display: flex;
    align-items: center;
    @include vw(padding-top, 132px);
    @include vw(padding-bottom, 150px);
  }

  @media screen and ($min1920) {
    padding-top: 132px;
    padding-bottom: 150px;
  }

  .side-menu-btns {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    backface-visibility: hidden;
    margin-top: 30px;

    @media screen and ($mobile_all) {
      padding-top: 25px;
      align-items: flex-start;
      flex-flow: row wrap;
      justify-content: center;
      margin: 10px;
    }

    @media screen and ($min1024) {
      flex-flow: row nowrap;
      justify-content: center;
      gap: 30px;
    }
  }

  .side-menu-quiz-btn {
    @media screen and ($mobile_all) {
      margin: 10px;
    }
  }

  .background-flower-path-patterns {
    pointer-events: none;
    @include pabs;
    @include fit;
    bottom: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    z-index: 1;

    @media screen and ($mobile_all) {
      display: none;
    }

    @media screen and ($min1024) {
      @include vw(left, -80px);
      @include vw(height, 1448px);
      @include vw(width, 2172px);
    }
  }

  .background-flower-path-svg {
    flex: 0 0 auto;
    height: 50%;
    width: 33.33%;
    opacity: 0.1;

    path {
      fill: transparent;
      stroke: $white;
    }
  }

  .container {
    z-index: 2;
  }

  .title-wrap-txt {
    margin-top: 25px;
  }

  .checkboxes-wrap {
    @media screen and ($min1024) {
      @include vw(margin-left, 3px);
    }

    @media screen and ($min1920) {
      margin-left: 3px;
    }
  }

  .checkboxes-hold {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }

  .checkbox-row {
    margin-bottom: 7px;

    @media screen and ($mobile_all) {
      width: 100%;
    }

    @media screen and ($min1024) {
      &:nth-of-type(odd) {
        width: 45%;
      }

      &:nth-of-type(even) {
        width: 55%;
      }
    }
  }

  .checkboxes-wrap-title {
    margin-bottom: 25px;

    @media screen and ($min1024) {
      @include vw(margin-bottom, 53px);
      @include vw(margin-left, 2px);
    }

    @media screen and ($min1920) {
      margin-bottom: 53px;
      margin-left: 2px;
    }
  }

  .quiz-pagination-wrap {
    display: flex;
    align-items: center;
    margin-top: 25px;

    @media screen and ($min1024) {
      @include vw(margin-top, 46px);
      @include vw(margin-left, 2px);
    }

    @media screen and ($min1920) {
      margin-top: 46px;
      margin-left: 2px;
    }

    .buttons-wrap {
      margin-left: auto;

      @media screen and ($min1024) {
        @include vw(margin-left, 324px);
      }

      @media screen and ($min1920) {
        margin-left: 324px;
      }
    }
  }

  .seperator {
    position: relative;
    margin: 0 38px 0 44px;
    width: 15px;
    height: 29px;

    @media screen and ($min1024) {
      @include vw(margin, 0 38px 0 44px);
      @include vw(width, 15px);
      @include vw(height, 29px);
    }

    @media screen and ($min1920) {
      margin: 0 38px 0 44px;
      width: 15px;
      height: 29px;
    }

    &::before {
      content: '';
      display: inline-block;
      backface-visibility: hidden;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(25deg);
      width: 2px;
      height: 100%;
      background-color: $green-1;
    }
  }
}
