.img-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;

  img {
    object-fit: contain;
    object-position: 50% 0%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  &.aspect-ratio-111-52 {
    @include aspectRatioBox(111px, 52px);
  }

  &.aspect-ratio-180-70 {
    @include aspectRatioBox(180px, 70px);
  }

  &.aspect-ratio-172-117 {
    @include aspectRatioBox(172px, 117px);
  }

  &.aspect-ratio-170-170 {
    @include aspectRatioBox(170px, 170px);
  }

  &.aspect-ratio-21-15 {
    @include aspectRatioBox(21px, 15px);
  }

  &.aspect-ratio-1-1 {
    @include aspectRatioBox(1px, 1px);
  }

  &.aspect-ratio-518-292 {
    @include aspectRatioBox(518px, 292px);
  }
}

.parallax-img {
  @media screen and ($mobile_all) {
    height: calc(100% + 100px);
  }
}

.swiper-prev,
.swiper-next {
  cursor: pointer;

  &.swiper-button-disabled {
    opacity: 0.3 !important;
    cursor: not-allowed;
  }
}

.b-lazy:not(.dont-show) {
  opacity: 0;
  transition: opacity 0.5s $easeOutSine;

  &.b-loaded {
    opacity: 1;
  }
}

.line-outer {
  overflow: hidden;
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  // height: 1px;
  // width: 1px;
  overflow: hidden;
  clip: rect(
    1px 1px 1px 1px
  ); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(
    1px,
    1px,
    1px,
    1px
  ); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(
    50%
  ); /*modern browsers, clip-path works inwards from each corner*/
  // white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
  // opacity: 0;
  color: transparent !important;
  background-color: transparent !important;
  text-decoration: none;
  font-size: inherit;
  width: fit-content;
  height: fit-content;
  br {
    display: none;
  }
}

.op-0 {
  opacity: 0;
}

.anim-translate-y {
  backface-visibility: hidden;
}

.char {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  will-change: opacity;
}

// global reusables
html {
  scrollbar-color: $green-1 rgba(222, 222, 222, 0.75);
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $green-1;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(222, 222, 222, 0.75);
  border-radius: 5px;
  box-shadow: inset -30px 10px 12px #f0f0f0;
}

[data-scroll-container-inner] {
  .scrollbar-track {
    background: rgba(222, 222, 222, 0.75);
    border-radius: 5px;
    box-shadow: inset -30px 10px 12px #f0f0f0;
  }

  .scrollbar-thumb {
    background: $green-1;
  }
}

#__bs_notify__,
#__bs_notify__ *,
.bs-msg {
  font-size: 16px !important;
  opacity: 1 !important;
  color: #ffffff !important;
  z-index: 10000000;
}

.hbspt-form .hs-input:not(input[type='checkbox'])::placeholder {
  font-size: 14px !important;
  opacity: 1;

  @media screen and ($max1024) {
    font-size: 14px !important;
  }
  @media screen and ($max480) {
    font-size: 12px !important;
  }
}

.gap {
  &-10 {
    gap:10px;
  }
  &-30 {
    gap:30px;
  }
}

.flex-even > * {
  flex:1;
}

.flex-column {
  flex-direction:column;

  &-1024 {
    @media screen and ($max1024){
      flex-direction:column;
    }
  }
}

.screen-dark::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(90.01deg, rgba(0, 0, 0, .56) .01%, rgba(0, 0, 0, .415015) 40.48%, rgba(0, 0, 0, 0) 80.45%);
}

ol.numbered-list-styled {
  list-style: none;
  counter-reset: item;

  li {
    counter-increment: item;
    margin-bottom: 5px;
  }
  li:before {
    content: counter(item);
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    color: #002ea6;
    line-height: 26px;
    font-size: 16px;
    font-weight:600;
    text-align: center;
    background: #17fcc4;
    border-radius: 50%;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index:9999;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: #002ea6;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateY(-110%);
  transition:all 0.3s ease;

  .popup-wrap {
    position: relative;
    width: 70%;
    margin: 0 auto;
    padding: 40px 0 0;

    @media screen and ($max1024){
      width:80%;
      padding-top:100px;
    }

    @media screen and ($min1024){
      width: 35%;
      padding: 100px 0 60px;
    }
  }

  h3 {
    color:$white;
  }
  p {
    color:$green-1;
  }

  .hbspt-form {
    margin-top:30px;
    padding:0;
    background:transparent;
    border-radius:0;
  }
}
html.open-popup {
  overflow: hidden;

  .popup {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
}