.sk-block,
.sk-block-sk {
  padding-top: $sk-block-padding;
  padding-bottom: $sk-block-padding;

  img.size-full {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }

  @media screen and ($mobile){
    padding-top:50px;
    padding-bottom:50px;
  }
}

.sk-block-sk {
  .inner-pages-slider-section {
    padding-top: $sk-block-padding;
    padding-bottom: $sk-block-padding;

    @media screen and ($mobile){
      padding-top:50px;
      padding-bottom:50px;
    }
  }
}

.sk-block-0 {
  .img- {
    &no {
      width: 20px;
      height: 20px;
    }
    &yes {
      width: 24px;
      height: 24px;
    }
  }
  &:not(.block--p) {
    .container .media-block {
      .img-wrap {
        margin-top: 0 !important;
        margin-bottom: 64px !important;
      }
    }
  }
}
