.admin-bar {
  .header {
    padding-top: 32px;
  }
}

.header .logo-on-scroll {
  opacity: 0 !important;
}

html.header-toggled-state .header .logo-anim-white.logo-on-scroll {
  opacity: 1 !important;
}

html.header-toggled-state .header .logo-no-scroll {
  opacity: 0 !important;
}

html.header-toggled-state.header-opened-dropdown .header .logo-no-scroll,
html.header-toggled-state.mobile-menu-opened .header .logo-no-scroll {
  opacity: 0 !important;
}

html.header-toggled-state.header-opened-dropdown .header .logo-anim-blue.logo-on-scroll,
html.header-toggled-state.mobile-menu-opened .header .logo-anim-blue.logo-on-scroll {
  opacity: 1 !important;
}

.header:not(.header-landing) {
  background-color: $blue-1;
}

html {
  &[lang='es-US'] {
    .main-header {
      .header-links-wrap,
      .mobile-menu-btn,
      .callback-popup-btn {
        display:none;
      }
    }
  }

  &.lang-fr {
    .main-header .dropdown-links-wrap.industry-row {
      grid-template-columns:auto;
    }
  }

  &.header-offset-homepage {
    .hero-section {
      @media screen and ($min1024) {
        @include vw(padding-top, 258px);
      }

      @media screen and ($min1920) {
        padding-top: 258px;
      }
    }
  }

  .landing-template-btn.revealed {
    .btn-bg-el {
      background-color: $green-1;

      &::before {
        background-color: $white;
      }
    }

    &:hover,
    &:focus {
      color: $blue-1;
    }
  }

  &.header-toggled-state {
    header {
      .content-wrap {
        @media screen and ($mobile_sm) {
          @include vw(padding-bottom, 5px, 500);
        }
      }

      @media screen and ($mobile_all) {
        .mobile-menu-btn {
          border: 1px solid $white;
        }

        .line-el {
          background-color: $white;
        }
      }

      @media screen and ($min1024) {
        top: 0;

        .dropdown-item-links {
          border-radius: 0px 0px 30px 30px;
        }
      }

      .btn-default.btn-green-1.landing-template-btn {
        .btn-bg-el {
          // background-color: $green-1;

          &::before {
            background-color: $white;
          }
        }

        &:hover,
        &:focus {
          color: $blue-1;
        }
      }

      .logo-anim-white {
        opacity: 1;
      }

      .logo-anim-blue {
        opacity: 0;
      }

      .header-logo-white {
        opacity: 1;
      }

      .header-logo-blue {
        opacity: 0;
      }

      .lottie-anim-logo {
        @media screen and ($min1024) {
          @include vw(width, 190px);
        }

        @media screen and ($min1920) {
          width: 190px;
        }
      }

      .header-logo-link {
        @media screen and ($min1024) {
          /*
          margin-top: 15px;
          */
          @include vw(width, 152px);
          @include vw(height, 51px);
          
        }

        @media screen and ($min1920) {
          /*
          margin-top: 15px;
          */
          width: 152px;
          height: 51px;
          
        }
      }

      .content-wrap {
        @media screen and ($min1920) {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }

      @media screen and ($min1024) {
        .statement-analysis-btn {
          margin-top: 0;
        }

        .header-links-wrap {
          margin-top: 0;
        }

        .nav-extras {
          margin-bottom: 0.5vw;
        }
      }

      // .dropdown-items-links-hold {
      //   @media screen and ($min1024) {
      //     @include vw(margin, 78px 400px 0 531px);
      //   }

      //   @media screen and ($min1920) {
      //     margin: 78px 400px 0 531px;
      //   }
      // }
    }
  }

  &.mobile-menu-opened {
    overflow: hidden;

    @media screen and ($max1024){
      #ensConsentWidget,
      .btn-chat-close,
      #sekure-connect-chat {
        display: none;
      }
    }

    header {
      .header-links-wrap {
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
      }

      .header-links-content-wrap {
        opacity: 1;
        transition-delay: 0.15s;
      }

      .nav-wrap {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }

      .logo-anim-white {
        opacity: 0;
      }

      .logo-anim-blue {
        opacity: 1;
      }

      .header-logo-white {
        opacity: 0;
      }

      .header-logo-blue {
        opacity: 1;
      }

      .mobile-menu-btn {
        border: 1px solid $blue-1;
      }

      .line-el {
        background-color: $blue-1;
      }
      
      .dropdown-link-cta {
        .btn-default:hover .btn-txt,
        .btn-default:focus .btn-txt {
          color: #fff;
        }
      }

      .statement-analysis-btn {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        text-align: left;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &.header-opened-dropdown {
    #ensConsentWidget,
    #cookie-law-info-bar,
    .sk-chat,
    .sk-sticky {
      z-index: 0;
    }

    header {

      .statement-analysis-btn {
        .btn-bg-el {
          border: 1px solid $blue-1;
        }

        &.btn-default.btn-white.section-color-blue .btn-bg-el::before {
          background-color: $blue-1;
        }

        &:hover,
        &:focus {
          color: $white !important;
        }
      }

      .header-links-content-wrap {
        /*
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $gray-1;
          backface-visibility: hidden;
        }
        */
      }

      .header-link-item,
      .dropdown-item-wrap {
        &.opened-dropdown {
          .dropdown-arrow-btn {
            transform: translateY(-40%) rotate(-180deg);
          }
        }

        &:hover,
        &:focus {
          //color: $red-2;

          .dropdown-arrow-btn::before {
            //border-color: $red-2;
          }

          .dropdown-item-links {
            height:auto;
          }
        }
      }

      .statement-analysis-btn::before {
        //border: 1px solid $blue-1;
      }

      .logo-anim-white {
        //opacity: 0;
      }

      .logo-anim-blue {
        //opacity: 1;
      }

      .header-logo-white {
        //opacity: 0;
      }

      .header-logo-blue {
        //opacity: 1;
      }
    }
  }
}

.header-toggled-state {
  .header {
    position: fixed;

    .cta-text {
      p {
        color: $white;
      }
    }

    @media screen and ($max1024){
      .btn-default.btn-green-1 .btn-bg-el:before {
        background-color: #002ea6 !important;
      }
    }
  }
}

/*
.mobile-menu-opened,
.home,
.page-home {
  .header {
    position: fixed;
  }
}
*/

.header {
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;

  .container-lg {
    @media screen and ($max1024){
      max-width:100%;
    }
  }

  .content-wrap {
    display: flex;
    transition:
      background-color 0.3s $easeOutSine,
      // border-radius 0.3s $easeOutSine,
      // box-shadow 0.3s $easeOutSine,
      padding-top 0.3s $easeOutSine,
      padding-bottom 0.3s $easeOutSine;

    @media screen and ($mobile_all) {
      justify-content: space-between;
      align-items: center;
    }

    @media screen and ($mobile_sm) {
      @include vw(padding-top, 5px, 500);
      @include vw(padding-bottom, 15px, 500);
    }

    @media screen and ($mobile_lg) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media screen and ($min1024) {
      align-items: flex-end;
      justify-content: space-between;
      border-radius: 30px;
      @include vw(padding-top, 32px);
      @include vw(padding-bottom, 28px);
    }



    @media screen and ($min1920) {
      padding-top: 32px;
      padding-bottom: 28px;
    }
  }

  .lottie-anim-logo {
    display: inline-block;
    width:100%;
    @include pabs;
    left:-1vw;
    bottom:-1vw;
    z-index:9;
    /*
    top: 45%;
    transform: translateY(-50%);
    */

    @media screen and ($mobile_sm) {
      // left: -23px;
      // width: 190px;
      // @include vw(left, -10px, 500);
      @include vw(width, 190px, 500);
    }

    @media screen and ($mobile_lg) {
      // left: -23px;
      width: 190px;
    }

    @media screen and ($min1024) {
      // @include vw(top, -37px);
      // @include vw(left, -32px);
      @include vw(width, 271px);
    }
    @media screen and ($min1920) {
      // top: -37px;
      // left: -32px;
      width: 271px;
    }
  }

  .header-logo-link {
    /*
        display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 100px;
    width: 11.5vw;
    overflow: hidden;
    flex: 0 0 auto;
    margin-top: 15px;
    position: relative;
    z-index: 2;
    transition:
      height 0.3s $easeOutSine,
      opacity 0.1s $easeOutSine,
      width 0.3s $easeOutSine;
    // margin-top 0.3s $easeOutSine,
    // transform 0.5s $easeOutSine;
    @include background-contrast-fix;
    */
    z-index:9;

    &:hover,
    &:focus {
      .lottie-anim-logo {
        // transform: scale(1.05) translateY(-50%);
        transform: scale(1.05);
      }
    }
    @media screen and ($mobile_sm) {
      
      @include vw(width, 197px, 500);
      @include vw(height, 66px, 500);
      
    }

    @media screen and ($mobile_lg) {
      
      width: 197px;
      height: 66px;
      
    }

    @media screen and ($min1024) {
      /*
      @include vw(margin-top, 6px);
      */
      @include vw(width, 217px);
      @include vw(height, 73px);

    }

    @media screen and ($min1920) {
      /*
      margin-top: 6px;
      */
      width: 217px;
      height: 73px;
    }

    &.lottie-anim-init {
      .header-logo-img {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .lottie-anim-logo {
    opacity: 0;
    transition:
      opacity 0.3s $easeOutSine,
      width 0.3s $easeOutSine,
      margin-top 0.3s $easeOutSine,
      transform 0.5s $easeOutSine;
  }

  .logo-anim-white {
    opacity: 1;
  }

  .logo-anim-blue {
    opacity: 0;
  }

  .header-logo-white {
    opacity: 1;
  }

  .header-logo-blue {
    opacity: 0;
  }

  .header-logo-img {
    opacity: 0;
    transition: opacity 0.3s $easeOutSine;
  }

  .header-logo-white {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition:
      max-width 0.3s $easeOutSine,
      width 0.3s $easeOutSine,
      margin-top 0.3s $easeOutSine,
      transform 0.3s $easeOutSine;
  }

  .nav-wrap {
    @media screen and ($min1024){
      display: flex;
      justify-content:flex-end;
      align-items: flex-end;
      flex-wrap: wrap;
    }
    @media screen and ($max1024){
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      height: 100vh;
      width: 100vw;
      padding-bottom: 30px;
      padding-top: 140px;
      background-color: #fff;
      overflow-x: hidden;
      overflow-y: hidden;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
      transition:
        opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1),
        visibility 0.3s cubic-bezier(0.61, 1, 0.88, 1),
        transform 0.3s cubic-bezier(0.61, 1, 0.88, 1);
    }
  }

  .nav-extras {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 1vw;
    transition: margin 0.3s ease-in-out;

    a {
      font-size: 16px;

      @media screen and ($min1024){
        color:#fff;
      }
    }

    .search-icon {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      margin-right:10px;
    }
    .search-icon .search-mobile {
      display: none;
    }
    input#s {
      margin-bottom: 1rem;
    }

    @media screen and ($max1024){
      display: flex;
      //flex-direction: row-reverse;
      justify-content: space-between;
      height:30px;
      line-height:30px;
      width: 100%;
      margin-bottom: 0;
      padding-left: 40px;
      padding-right: 40px;
      border-top: solid 1px #002ea6;
      border-bottom: solid 1px #002ea6;

      .search-icon .search-desktop,
      .support-icon {
        display: none;
      }

      .search-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        padding-left: 15px;
        border-left: solid 1px #002ea6;
      }
      .search-icon .search-mobile {
        display: block;
      }
    }
  }

  .lang-wrap {
    display: flex;
    align-items: center;
    //height: 30px;
    //font-family: 'Libre Franklin';

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 48%;
      z-index: -1;
    }

    &::before {
      left: 0;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      background: url($ROOT_PATH + 'media/svgs/web.svg') no-repeat center center;
    }
    &::after {
      right: 3px;
      -webkit-transform: translateY(-50%) rotate(-45deg);
      transform: translateY(-50%) rotate(-45deg);
      height: 6px;
      width: 6px;
      border-bottom: 2.5px solid #17fcc4;
      border-left: 2.5px solid #17fcc4;
      border-right-color: #17fcc4;
      border-top-color: #17fcc4;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .lang-current {
      padding-left: 22px;
      padding-right: 15px;
      color: #fff;
    }

    .lang-icon {
      position: absolute;
      left: 0;
      z-index: -1;
    }
    select {
      padding-left: 15px;
    }

    @media screen and ($max1024){
      height:30px;
      line-height:30px;

      .lang-current,
      #lang_toggle {
        color: #002ea6;
        font-weight: 700;
      }
      .lang-current {
        padding-left: 0;
        padding-right: 20px;
      }
      .lang-current,
      #lang_toggle a {
        font-size: 12px;
      }
      &::before {
        content: none;
      }
      &::after {
        top: 10px;
        transform: rotate(-45deg);
        border-bottom: 2px solid #002ea6;
        border-left: 2px solid #002ea6;
        border-color: #002ea6;
      }
    }
  }

  #lang_toggle {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 9;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 6px 6.5px 0 rgb(0 0 0 / 20%);
    transition: all 0.3s ease;

    a {
      padding: 0 15px;
      line-height: 30px;
      height: 30px;
      color: #002ea6;
      border: solid 1px transparent;

      &:focus {
        border-color: #17fcc4;
      }
      &.current {
        color: #fff;
        background: #002ea6;
      }
    }
  }

  .statement-analysis-btn {
    flex: 0 0 auto;
    transition:
      margin-top 0.3s $easeOutSine,
      color 0.5s $easeOutSine;

    @media screen and ($mobile_all) {
      display: none;

      .btn-bg-el {
        border-radius: 0;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 9;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50px;
        background: #002ea6;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 9;
        top: 50%;
        transform: translateY(-50%) rotate(-135deg);
        right: 20px;
        width: 15px;
        height: 15px;
        backface-visibility: hidden;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        border-right-color: #fff;
        border-top-color: #fff;
      }
    }

    @media screen and ($min1024) {
      @include vw(margin-top, 12px);
      @include vw(margin-right, -16px);
      &:hover,
      &:focus {
        color: $blue-1;
        &::before {
          background-color: $green-1;
        }
      }

      &.mobile-btn {
        display:none;
      }
    }
    @media screen and ($min1920) {
      margin-top: 12px;
      margin-right: -16px;
    }
  }

  .header-links-wrap {
    flex: 0 1 auto;
    transition: margin-top 0.3s $easeOutSine;

    @media screen and (max-width: 500px) {
      &.offset-x-mobile-47 {
        padding-left: 15px;
        padding-right: 15px;

        .dropdown-items-links-hold {
          .dropdown-items-link.icon-link {
            padding-left: 37px;
            padding-top: 15px;
          }
          padding-top: 20px;
          .dropdown-links-wrap {
            padding-bottom: 20px;
          }
        }
      }
    }

    @media screen and ($mobile_all) {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
      width: 100vw;
      height: 100%;
      max-height: 100vh;
      max-height: calc(100vh - 175px);
      top: 0;
      z-index: 0;
      left: 0;
      background-color: $white;
      overflow-x: hidden;
      overflow-y: hidden;
      padding-bottom: 0;
      transition:
        opacity 0.3s $easeOutSine,
        visibility 0.3s $easeOutSine,
        transform 0.3s $easeOutSine;

      position: relative;
      padding-top: 0;
      box-shadow: none;

      .statement-analysis-btn {
        display: inline-block;
        margin-top: 25px;
        margin-left: 0;
      }
    }

    @media screen and ($mobile) {
      box-shadow: 4px 5px 3px #acacac6e;
    }

    @media screen and ($min1024) {
      margin-left: auto;
      margin-right: 2vw;
    }

    @media screen and ($min1920) {
      margin-top: 14px;
      margin-right: 25px;
      padding-left: 40px;
      padding-right: 14px;
    }

    .dropdown-item-wrap {
      @media screen and ($max1024){
        .dropdown-arrow-btn {
          position: absolute;
          left: calc(100% - 30px);
          top: auto;
          transform: none;
          transition: all 0.3s ease;

          &::before {
            border-color: #002ea6;
          }
        }
        &.opened {
          .dropdown-items-links-hold {
            left: 0;
          }
          .dropdown-arrow-btn {
            left: 0;
            transform: rotate(-180deg);
          }
          .header-link-item.has-dropdown {
            color: #fc4a6b !important;
            padding-left: 30px;
            transition: all 0.3s ease;
          }
          .header-link-item.has-dropdown,
          .dropdown-arrow-btn:before {
            border-color: #fc4a6b;
          }
        }
      }
    }
  }

  .filler-item-link.dropdown-items-link {
    @media screen and ($mobile_all) {
      display: none;
    }
  }

  .header-links-content-wrap {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    @media screen and ($mobile_all) {
      position:absolute;
      top:0;
      width:100%;
      padding:0 40px;
      height: 100%;
      margin-bottom: 75px;
      overflow: hidden;
      overflow-y: hidden;
      opacity: 0;
      transition: opacity 0.3s $easeOutSine;
    }
    @media screen and ($min1024) {
      flex-flow: row nowrap;
      align-items: center;
      gap:1.6667vw;
    }
  }

  .dropdown-item-wrap.opened .dropdown-arrow-btn {
    transform: translateY(-40%) rotate(-180deg);
  }

  @media screen and ($max1024) {
    .dropdown-item-wrap {
      margin: 0;
      overflow: unset;
    }
    .dropdown-item-wrap.opened {
      overflow-y: auto;

      .dropdown-item-links {
        height: auto !important;
      }
    }

    .btn-default.clone {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      width: 100%;
      text-align: left;
      font-size: 16px;
      line-height: 20px;
    }
    .btn-default.clone::before,
    .btn-default.clone::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 9;
    }
    .btn-default.clone::before {
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      background: #002ea6;
    }
    .btn-default.clone::after {
      top: 50%;
      transform: translateY(-50%) rotate(-135deg);
      right: 20px;
      width: 15px;
      height: 15px;
      backface-visibility: hidden;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      border-right-color: #fff;
      border-top-color: #fff;
    }
    .btn-default.clone .btn-bg-el {
      border-radius: 0;
      background-color: #17fcc4;
    }
  }

  .header-bg-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: background-color 0.3s $easeOutSine;
    background-color: $white;

    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: $box-shadow;
    transition: border-radius 0.3s $easeOutSine;
    opacity: 0;
  }

  .dropdown-item-links {
    height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    @media screen and ($min1024) {
      position: fixed;
      left: 0;
      top: 0px;
      width: calc(100% - 3.646vw);
      margin: 0 1.823vw;
      background: rgb(255 255 255 / 0);
      transition: all 0.3s ease;
      overflow: visible;
      //   border-radius: 30px;
      //   position: absolute;
      //   left: 0;
      //   width: 100%;
      //   box-shadow: $box-shadow;
      //   transition: border-radius 0.3s $easeOutSine;
    }

    &.opened-dropdown {
      .dropdown-items-links-hold {
        @media screen and ($min1024) {
          background:#fff;
        }
        @media screen and ($max1024){
          left: 0;
        }
      }
    }
  }

  .dropdown-items-link {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 25px;
    transition: color 0.3s $easeOutSine;
    @media screen and ($mobile_all) {
      font-size: 16px;
      line-height: 24px;
    }
    @media screen and ($min1024) {
      width: calc(25% - 1.042vw);
      @include vw(margin, 11px 10px);
      @include vw(padding, 5px 0);
    }
    @media screen and ($min1920) {
      width: calc(25% - 20px);
      margin: 11px 10px;
      padding: 5px 0;
    }

    &.icon-link {
      padding-left: 37px;
      @media screen and ($min1024) {
        @include vw(padding-left, 37px);
      }
      @media screen and ($min1920) {
        padding-left: 37px;
      }
      .rates-svg {
        .st0 {
          fill: $gray-10;
        }
      }

      .equipment-svg-header {
        .st0 {
          fill: $gray-10;
        }
      }

      .new-business-svg {
        .st1 {
          fill: $gray-10;
        }
      }
    }
    &:hover,
    &:focus {
      color: $blue-5;

      .retail-svg {
        .st0 {
          fill: $blue-1;
        }

        .st1 {
          fill: $green-1;
        }
      }

      .health-wellness-svg {
        .st0 {
          fill: $green-1;
        }

        .st1 {
          fill: $blue-1;
        }
      }

      .grocery-svg {
        .st0 {
          fill: $blue-1;
        }

        .st1 {
          fill: $green-1;
        }
      }

      .ecommerce-svg {
        .st0 {
          fill: $blue-1;
        }

        .st1 {
          fill: $green-1;
        }
      }

      .restaurant-svg {
        .st0 {
          fill: $green-1;
        }

        .st1 {
          fill: $blue-1;
        }
      }

      .hospitality-svg {
        .st0 {
          fill: $blue-1;
        }

        .st1 {
          fill: $green-1;
        }
      }

      .fashion-boutiques-svg {
        .st0 {
          fill: $blue-1;
        }

        .st1 {
          fill: $green-1;
        }
      }

      .homeware-store-svg {
        .st0 {
          fill: $blue-1;
        }

        .st1 {
          fill: $green-1;
        }
      }

      .professional-services-svg {
        .st0 {
          fill: $green-1;
        }

        .st1 {
          fill: $blue-1;
        }
      }

      .wholesale-icon-svg {
        .st0 {
          fill: $green-1;
        }

        .st1 {
          fill: $blue-1;
        }
      }

      .non-profit-svg {
        .cls-1 {
          fill: $blue-25;
        }

        .cls-2 {
          fill: $green-1;
        }
      }

      .beauty-salons-svg {
        .cls-1 {
          fill: $blue-25;
        }

        .cls-2 {
          fill: $green-1;
        }
      }

      .hardware-svg {
        .cls-1 {
          fill: $blue-25;
        }

        .cls-2 {
          fill: $green-1;
        }
      }

      .rates-svg {
        .st0 {
          fill: $blue-1;
        }
      }

      .equipment-svg-header {
        .st0 {
          fill: $blue-1;
        }
      }

      .new-business-svg {
        .st1 {
          fill: $blue-1;
        }
      }
    }
    @media screen and ($min1024) {
      & {
        .rates-svg {
          .st0 {
            fill: $gray-10;
          }
        }

        .equipment-svg-header {
          .st0 {
            fill: $gray-10;
          }
        }

        .new-business-svg {
          .st1 {
            fill: $gray-10;
          }
        }
      }
      &:hover,
      &:focus {
        .rates-svg {
          .st0 {
            fill: $blue-1;
          }
        }

        .equipment-svg-header {
          .st0 {
            fill: $blue-1;
          }
        }

        .new-business-svg {
          circle {
            stroke: $blue-1;
          }
          .st1 {
            fill: $blue-1;
          }
        }
      }
    }
  }

  .solution-svg {
    flex: 0 0 auto;
    backface-visibility: hidden;
    width: 25px;
    @media screen and ($min1024) {
      @include vw(width, 25px);
      .st0,
      .st1,
      .cls-1,
      .cls-2 {
        fill: $gray-10;
      }
      margin-right:10px;
    }
    @media screen and ($min1920) {
      width: 25px;
    }

    .st0,
    .st1,
    .cls-1,
    .cls-2 {
      transition: fill 0.3s $easeOutSine;
    }
  }

  .dropdown-items-links-hold {
    transition: all 0.3s ease;

    @media screen and ($min1024) {
      padding-top: 3.125vw;
      box-shadow: 0 5px 24px 0 rgba(0, 46, 166, 0.1);
      border-radius: 30px;
    }

    @media screen and ($max1024){
      position: relative;
      left: 103%;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.3s ease;
    }

    // @media screen and ($min1024) {
    //   @include vw(margin, 99px 0 0 0);
    //   margin-left: auto;
    //   margin-right: auto;
    //   max-width: 800px;
    //   @include vw(padding-top, 60px);
    //   position: absolute;
    // }
    // @media screen and ($min1920) {
    //   margin: 99px 400px 0 531px;
    //   padding-top: 60px;
    // }
  }

  .dropdown-links-wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 40px;
    @media screen and ($min1024) {
      display: grid;
      padding: 3.125vw;
      padding-top: 0;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      
      &.columns-2 {
        grid-template-columns: 1fr 1fr;
      }
      &.columns-3 {
        grid-template-columns: 1fr 1fr 1fr;
      }
      &.columns-2.company-row {
        grid-template-columns: 2fr 1fr;
      }
      &.industry-row {
        grid-template-columns: 2fr 1fr;
      }
    }
    @media screen and ($min1920) {
      margin: -11px -10px;
      padding-bottom: 40px;
    }

    &.single-column-links {
      flex-flow: column nowrap;
      width: fit-content;
      @media screen and ($min1024) {
        @include vw(margin, -11px 0);
      }
      @media screen and ($min1920) {
        margin: -11px 0;
      }
      .dropdown-items-link {
        @media screen and ($min1024) {
          width: auto;
          @include vw(margin, 11px 0);
        }
        @media screen and ($min1920) {
          margin: 11px 0;
        }
      }
    }
    
    &.menu-plain {
      .links-column {
        border-right: none;
      }

      .dropdown-link-wrap:hover, .dropdown-link-wrap:focus {
        background: transparent;
      }
    }

    @media screen and ($max1024){
      flex-direction: column;
      align-items: stretch;

      .dropdown-items-link {
        margin-bottom: 0;

        img {
          margin-left:10px;
        }

        svg {
          margin-right:10px;
        }
      }
    }
  }

  .links-column {
    height: 100%;

    &:last-of-type {
      border-right: none;
    }

    @media screen and ($max1024){
      &:not(.contact-icons) > .dropdown-link-wrap {
        display: none;
      }
      &.open-submenu {
        .column-heading {
          padding-left: 30px;
          color: #fc4a6b;
          border-color: #fc4a6b;

          &::after {
            left: 12px;
            border-color: #fc4a6b;
            transform: translateY(-50%) rotate(-225deg);
          }
        }
      }
    }

    @media screen and ($min1024){
      border-right: solid 2px #008fc5;
    }

    .column-heading {
      width:100%;
      padding: 0 30px;

      @media screen and ($max1024){
        margin-bottom:0;
        padding: 24px 15px 5px 0;
        font-size: 18px;
        line-height: 30px;
        color: #002ea6;
        border-bottom: solid 1px #008fc5;
        cursor: pointer;
        transition: all 0.3s ease;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 9;
          transform: translateY(-50%) rotate(-135deg);
          top: calc(50% + 8px);
          left: calc(100% - 20px);
          width: 6px;
          height: 6px;
          backface-visibility: hidden;
          border-bottom: 2.5px solid #002ea6;
          border-left: 2.5px solid #002ea6;
          border-right-color: #002ea6;
          border-top-color: #002ea6;
          transition: all 0.3s ease;
        }
      }
    }

    &.industry-icons {
      @media screen and ($min1024) {
        display: flex;
        flex-wrap: wrap;

        .dropdown-link-wrap {
          width: 33%;
          padding: 10px 30px;

          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
    }

    &.contact-icons .dropdown-link-wrap {
      @media screen and ($min1024) {
        display: flex;
        align-items: center;
        width: 50%;

        &:hover,
        &:focus {
          background: #ccd5ed;
        }
      }
    }
  }

  .payments-row {
    .links-column.industry-icons {
      .dropdown-link-wrap {
        @media screen and ($min1024) {
          &:hover,
          &:focus {
            background: #ccd5ed;
          }
        }
      }
    }
  }

  .dropdown-link-wrap {
    transition: all 0.3s ease;

    @media screen and ($min1024){
      padding: 10px 30px;

      &:hover,
      &:focus {
        background: #ccd5ed;
      }

      a {
        font-family: 'Red Hat Display';
        display: flex;
        align-items: center;
        width: auto;
        margin: 0 0 8px;
        padding: 0;
        line-height: 24px;
        font-weight: 700;

        img {
          margin-left: 8px;
        }

        svg {
          margin-right:10px;
        }

        &:hover img,
        &:focus img {
          animation-name: arrowMovement;
          animation-duration: 0.6s;
          animation-fill-mode: forwards;
        }
      }
    }

    @media screen and ($max1024){
      padding: 24px 0 10px;
      border-bottom: solid 1px #17fcc4;

      .column-desc {
        display:none;
      }
    }
  }

  .dropdown-link-cta {
    @media screen and ($max1024){
      display:none;
      margin-top:20px;

      &.mobile-show {
        display:block;
      }

      h2, h3 {
        font-size: 18px;
        line-height: 30px;
      }
      p {
        display:none;
      }
      .hbspt-form {
        margin-top:0;
      }
    }
    @media screen and ($min1024){
      padding: 24px;
      margin-left: 30px;
      background: #ccd5ed;
      border-radius: 16px;
    }
  }

  .header-link-item {
    transition: color 0.3s $easeOutSine;
    padding-top: 20px;
    padding-bottom: 20px;

    @media screen and ($mobile_all) {
      font-size: $ft-18;
      color: $blue-1 !important;
    }

    @media screen and ($min1024) {
      @include vw(padding-top, 17px);
      @include vw(padding-bottom, 16px); // @include vw(padding-bottom, 19px);
      font-size:15px;
      line-height:30px;

      &:hover,
      &:focus {
        color: $green-1;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -6px;
        width: 0;
        height: 1px;
        background-color: $green-1;
        backface-visibility: hidden;
        transition: all 0.3s ease;
        pointer-events:none;
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -1.5vw;
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid #fff;
        visibility: hidden;
        transition: all 0.3s ease;
        pointer-events: none;
      }

      &.opened-dropdown {
        //color: $red-2 !important;
        a {
          //color: $red-2 !important;
        }
        &::before {
          width:calc(100% + 12px);
        }
        &::after {
          opacity: 1;
          visibility: visible;
        }

        .dropdown-arrow-btn::before {
          //border-color: $red-2 !important;
        }
      }

      &:hover,
      &:focus {
        &::before {
          transform: scaleX(1);
        }
      }

      &.active-item {
        color: $green-1 !important;
      }
    }

    @media screen and ($min1440) {
      @include vw(padding-bottom, 19px);
    }

    @media screen and ($min1920) {
      padding-top: 17px;
      padding-bottom: 19px;
    }

    &.has-dropdown {
      font-family: 'Red Hat Display';
      position: relative;
      z-index: 1;
      transition: color 0.3s $easeOutSine;
      padding-right: 20px;
      @media screen and ($min1024) {
        @include vw(padding-right, 20px);
        &:hover,
        &:focus {
          color: #17fcc4;
          .dropdown-arrow-btn::before {
            border-color: #17fcc4;
          }
        }
      }
      @media screen and ($min1920) {
        padding-right: 20px;
      }
    }

    @media screen and ($max1024){
      font-family: 'Red Hat Display';
      display: flex;
      align-items: center;
      width: 100%;
      padding: 24px 0 10px;
      line-height: 30px;
      border-bottom: solid 1px #008fc5;
    }
  }

  .dropdown-arrow-btn {
    display: inline-block;
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.3s $easeOutSine;
    top: 45%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    right: -10px;
    @media screen and ($min1024) {
      @include vw(width, 30px);
      @include vw(height, 30px);
      @include vw(right, -10px);
    }
    @media screen and ($min1920) {
      width: 30px;
      height: 30px;
      right: -10px;
    }
  }

  .dropdown-arrow-btn::before {
    content: '';
    display: inline-block;
    position: absolute;
    backface-visibility: hidden;
    left: 50%;
    top: 50%;
    transition:
      transform 0.3s $easeOutSine,
      border-color 0.3s $easeOutSine;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-left-style: solid;
    border-bottom-style: solid;
    border-color: $green-1;
    width: 6px;
    height: 6px;
    border-left-width: 2.5px;
    border-bottom-width: 2.5px;
    @media screen and ($min1024) {
      @include vw(width, 6px);
      @include vw(height, 6px);
      @include vw(border-left-width, 2.5px);
      @include vw(border-bottom-width, 2.5px);
    }
    @media screen and ($min1920) {
      width: 6px;
      height: 6px;
      border-left-width: 2.5px;
      border-bottom-width: 2.5px;
    }
  }

  .mobile-menu-btn {
    position: relative;
    z-index: 2;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $gray-9;
    width: 55px;
    height: 55px;
    margin-right: -6px;

    @media screen and ($min1024) {
      display: none;
    }
  }

  .hbspt-form {
    margin-top: 30px;
    padding: 0;
    background: transparent;
    border-radius: 0;

    .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
      color: #002ea6;
      background-color: #ccd5ed;

      @media screen and ($max1024){
        background-color:#fff;
      }
    }
    .hs-input:not(input[type='checkbox']) {
      border-radius: 5px;
    }
    .hs-input:not(input[type='checkbox']),
    .hs-input:not(input[type='checkbox']) option {
      color: #002ea6;
    }
    .hs-error-msg.hs-main-font-element {
      color: #ff034a;
    }
    .hs-form-field {
      margin-bottom: 24px;
    }
    .submitted-message {
      color: #002ea6 !important;
      font-family: 'Libre Franklin';
    }
    .submitted-message .btn-default .btn-txt {
      color: #002ea6 !important;
    }
    .submitted-message .btn-default .btn-txt:hover,
    .submitted-message .btn-default .btn-txt:focus {
      color: #17fcc4 !important;
    }

    @media screen and ($max1024){
      .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
        background-color:#fff;
      }
      .hs-input:not(input[type='checkbox']) {
        border-radius: 5px;
      }
    }
  }

  .line-el {
    transition: background-color 0.3s $easeOutSine;
    background-color: $white;
    display: inline-block;
    width: 21px;
    height: 2px;
    margin: 2px 0;
  }

  &.header-fr {
      @media screen and ($min1024) {
        .header-links-wrap {
          margin-right:1.25vw;
        }
      }
  }
}

@keyframes arrowMovement {
  from {
    margin-left: 5px;
  }
  to {
    margin-left: 12px;
  }
}
